<template>
  <div
    v-for="item in ITEMS"
    :key="item.id"
    class="
      w-full
      sm:w-52
      h-full
      sm:h-44
      p-2
      mobile:p-6
      flex flex-col
      items-center
      bg-white
      shadow-black
      hover:shadow-black-xl
      transition
      duration-300
      cursor-pointer
      font-barlow
      z-10
    "
    @click="goToItem(item.id)"
  >
    <div class="w-10 mobile:w-14 h-10 mobile:h-14 mb-2">
      <img :src="getImg(item.icon)" alt="" />
    </div>
    <span class="flex-1 font-semibold text-sm mobile:text-lg mb-1">
      {{ item.title }}
    </span>

    <div class="text-gray-400 text-xs mobile:text-sm">
      <div v-if="item.id === 'avisos'" class="w-20">
        <p>Resueltos:</p>
        <p>En curso:</p>
      </div>
      <div v-else class="w-20">

      </div>
    </div>
  </div>
</template>

<script lang="ts">
import homeItems from "@/store/homeItems.json";
import router from "@/router/index";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const ITEMS = homeItems;

    const getImg = (icon: string) => {
      return require("../assets/icons/home/" + icon);
    };

    // TODO Refactor
    const goToItem = (id:string) => {
      router.push({path: `/panel/${id}`})
    }
    return { ITEMS, getImg, goToItem };
  },
});
</script>
