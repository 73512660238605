
import homeItems from "@/store/homeItems.json";
import router from "@/router/index";
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const ITEMS = homeItems;

    const getImg = (icon: string) => {
      return require("../assets/icons/home/" + icon);
    };

    // TODO Refactor
    const goToItem = (id:string) => {
      router.push({path: `/panel/${id}`})
    }
    return { ITEMS, getImg, goToItem };
  },
});
