
import { defineComponent } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import HomeItems from "@/components/HomeItems.vue";

export default defineComponent({
  components: { TheHeader, HomeItems },
  setup() {
    return {};
  },
});
