<template>
  <the-header />
  <div class="relative mt-12 pb-5 bg-bg-gray h-full">
    <div class="w-full h-72 absolute top-0 z-10">
      <img
        src="@/assets/home-bg.jpg"
        alt=""
        class="hidden mobile:block w-full h-full object-cover"
      />
    </div>
    <div
      class="
        w-full
        max-w-screen-lg
        mx-auto
        px-3
        pt-6
        sm:pt-44
        gap-y-6 gap-x-6
        grid grid-cols-2
        auto-rows-fr
        sm:flex
        flex-wrap
        items-center
        justify-center
      "
    >
      <home-items />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import HomeItems from "@/components/HomeItems.vue";

export default defineComponent({
  components: { TheHeader, HomeItems },
  setup() {
    return {};
  },
});
</script>
